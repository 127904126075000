import {
  CompanyInvoiceTypePivot,
  CompanyMovementType,
  CustomerData,
  FirmData,
  Invoice,
  InvoiceMovementType,
  InvoiceMovementTypeData,
  InvoicePosition,
  InvoicePositionData,
  InvoiceReference,
  InvoiceType,
  InvoiceTypeData,
  PriceListData,
  TaxTypeData,
} from "@planetadeleste/vue-mc-gw";
import { Component, Vue } from "vue-property-decorator";
import {
  currencyFormat,
  DebounceFunction,
  negative,
  percent,
  positive,
  round,
  roundBy,
  RoundByPosition,
} from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import {
  castArray,
  debounce,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isEmpty,
  isNil,
  isNumber,
  isString,
  set,
  toPlainObject,
  uniqueId,
} from "lodash";
import { InvoiceModule } from "@/store/invoice";
import { debug } from "@/composables/debug";
import {
  applyDiscounts,
  invalidItemPosition,
} from "@/modules/invoices/tools/utils";
import { number } from "mathjs";
import useMath from "@/composables/math";
import { InvoiceMovementTypeCode, InvoiceTypeCode } from "@/types/utils";
import { canModuleAccess } from "@/services/moduleAccess";

export interface InvoicePaymentMethodConfig extends Record<string, any> {
  amount: number;
  bank_name?: string;
  account_serial?: string;
  account_number?: string;
  account_type?: string;
  account_code?: string | number;
  issue_at?: string;
  due_at?: string;
  holder?: string;
  number?: string;
}

export interface TaxTypeDataWithAmount extends TaxTypeData {
  amount?: number;
  total?: number;
}

const { sumBy, math } = useMath();

@Component
export default class InvoiceMixin extends Vue {
  forceReactive = 1;

  /**
   * Used to add empty position after remove any other invalid position
   *
   * @date 29/7/2022 - 09:08:57
   * @author Planeta del Este
   *
   * @type {boolean}
   */
  bAddEmpty = false;

  fnRemoveEmptyPositions: DebounceFunction = debounce(
    this._removeEmptyPositions,
    500,
    { maxWait: 1500 }
  );
  onSetTaxAmountFromPositionsDebounced!: DebounceFunction;

  get company() {
    return AppModule.company;
  }

  get companyMovementTypeConfig() {
    return AppModule.companyMovementTypeConfig;
  }

  get obInvoice() {
    return InvoiceModule.invoice;
  }

  set obInvoice(obModel: Invoice) {
    InvoiceModule.setInvoice(obModel);
  }

  get currency() {
    return InvoiceModule.currency;
  }

  get currencyRate() {
    return InvoiceModule.currencyRate;
  }

  get invoiceCurrencyId(): Partial<number> {
    return this.obInvoice.get("currency_id", 0);
  }

  get customerId(): Partial<number> {
    return this.obInvoice.get("customer_id", 0);
  }

  get customer(): Partial<CustomerData> {
    return this.obInvoice.get("customer", {});
  }

  get customerFirm(): Partial<FirmData> {
    return this.obInvoice.get("customer_firm", {});
  }

  set customerFirm(obData: Partial<FirmData>) {
    this.obInvoice.set("customer_firm", toPlainObject(obData));
  }

  get priceListId(): number {
    return this.obInvoice.get("price_list_id", 0);
  }

  get obPriceList(): Partial<PriceListData> {
    return this.obInvoice.get("price_list", {});
  }

  get positions(): InvoicePosition[] {
    return InvoiceModule.positions;
  }

  get references(): InvoiceReference[] {
    return InvoiceModule.references;
  }

  get discounts() {
    return InvoiceModule.discounts;
    // return InvoiceModule.discounts.map((obData) => {
    //   return obData instanceof Discount ? obData : new Discount(obData);
    // });
  }

  get invoicePaymentMethods() {
    return InvoiceModule.invoicePaymentMethods;
  }

  get invoiceMovementType(): Partial<InvoiceMovementTypeData> {
    return InvoiceModule.invoiceMovementType;
  }

  get invoiceType(): Partial<InvoiceTypeData> {
    return InvoiceModule.invoiceType;
  }

  get invoiceTypePivot(): Partial<CompanyInvoiceTypePivot> | undefined {
    return InvoiceModule.invoiceTypePivot;
  }

  get priceWithTax(): boolean | undefined {
    return InvoiceModule.priceWithTax;
  }

  get sTypeCode(): InvoiceTypeCode {
    return number(InvoiceModule.sTypeCode);
  }

  /**
   * Get total positions value
   *
   * @date 21/10/2022 - 08:41:13
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fSubtotalWithoutDiscounts() {
    return InvoiceModule.fSubtotalWithoutDiscounts;
  }

  /**
   * Get total discount value
   *
   * @date 21/10/2022 - 08:40:43
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fDiscounts() {
    return InvoiceModule.fDiscounts;
  }

  /**
   * Get total value without taxes
   *
   * @date 21/10/2022 - 08:40:07
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fSubtotal() {
    return InvoiceModule.fSubtotal;
  }

  get fSubTotalWithDiscounts() {
    return InvoiceModule.fSubTotalWithDiscounts;
  }

  get sSubtotalWithoutDiscounts() {
    return currencyFormat(
      positive(this.fSubtotalWithoutDiscounts),
      this.currency?.code
    );
  }

  get sDiscounts() {
    return currencyFormat(negative(this.fDiscounts), this.currency?.code, 3);
  }

  get sSubtotal() {
    return currencyFormat(this.fSubtotal, this.currency?.code);
  }

  get fTaxesResult() {
    return InvoiceModule.fTaxesResult;
  }

  /**
   * Get total value with tax
   *
   * @date 21/10/2022 - 08:39:14
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotal() {
    return InvoiceModule.fTotal;
  }

  /**
   * Get total paid value (sum of payment methods)
   *
   * @readonly
   * @type {number}
   */
  get fPaid() {
    return InvoiceModule.fPaid;
  }

  get fBalance() {
    return math.subtract(this.fTotalRounded, this.fPaid);
    // return round(math.subtract(this.fTotalRounded, this.fPaid), 2);
  }

  /**
   * Get rounded total value
   *
   * @date 21/10/2022 - 08:34:17
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotalRounded() {
    return InvoiceModule.fTotalRounded;
  }

  get sTotalRounded() {
    return positive(this.fTotalRounded);
  }

  /**
   * Get rounding value
   *
   * @date 21/10/2022 - 08:35:40
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundValue() {
    return InvoiceModule.fRoundValue;
  }

  get sRoundValue() {
    return positive(this.fRoundValue);
  }

  /**
   * Get currency setting of round by value
   *
   * @date 21/10/2022 - 08:30:47
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundBy(): RoundByPosition {
    return InvoiceModule.fRoundBy;
  }

  get rounded() {
    return (
      this.fRoundValue !== 0 &&
      this.iMovementType !== InvoiceMovementType.CODE_DEBT &&
      this.iMovementType !== 15
    );
  }

  get sTotal() {
    return currencyFormat(positive(this.fTotalRounded), this.currency?.code);
  }

  get fTotalReferences() {
    const fValue = sumBy(this.references, (obReference) => {
      let fValue = obReference.get("invoice.balance_value", 0);

      if (this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE) {
        fValue = obReference.get("invoice.total_price_value", 0);
      }

      return fValue;
    });

    return roundBy(fValue, this.fRoundBy);
  }

  get arTaxes(): TaxTypeDataWithAmount[] {
    return InvoiceModule.arTaxes;
  }

  get exists(): boolean {
    return !!this.obInvoice.id;
  }

  get inView(): boolean {
    return InvoiceModule.inView;
  }

  get signed(): boolean {
    return InvoiceModule.signed;
  }

  get signing(): boolean {
    return InvoiceModule.isSigning;
  }

  get maxUIAmount(): number {
    return InvoiceModule.maxUIAmount;
  }

  get subtotalExceedMaxUI(): boolean {
    return InvoiceModule.subtotalExceedMaxUI;
  }

  get requiredCustomerData() {
    return (
      this.subtotalExceedMaxUI || this.sTypeCode === InvoiceType.CODE_ERESGUARDO
    );
  }

  get canSign() {
    return InvoiceModule.canSign;
  }

  get iMovementType(): InvoiceMovementTypeCode {
    return InvoiceModule.iMovementType;
  }

  get totals() {
    return InvoiceModule.totals;
  }

  /**
   * Get true if invoice is e-Recibo type
   */
  get isReceiptType(): boolean {
    return !!this.sTypeCode && this.sTypeCode === 701;
  }

  /**
   * Get true if invoice is received
   *
   * @returns {boolean}
   */
  get isReceived(): boolean {
    return InvoiceModule.isReceived;
  }

  /**
   * Get true if invoice is type VENTA
   */
  get isSales(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_SALES;
  }

  /**
   * Get true if invoice movement is type RESGUARDO
   *
   * @returns {boolean}
   */
  get isReceipt(): boolean {
    return (
      [
        InvoiceMovementType.CODE_RECEIPT,
        InvoiceMovementType.CODE_RECEIPT_CORRECT,
      ].includes(this.iMovementType) ||
      this.sTypeCode === InvoiceType.CODE_ERESGUARDO
    );
  }

  /**
   * Get true if invoice movement is type CORRECCIÓN RESGUARDO
   */
  get isReceiptCorrect(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_RECEIPT_CORRECT;
  }

  /**
   * Get true if invoice movement is type ANULA COBRANZA
   */
  get isCancelDebt(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_CANCEL_DEBT;
  }

  /**
   * Get true if invoice movement is type PAGO.
   *
   * @return {boolean} true if invoice movement is type PAGO, false otherwise
   */
  get isPay(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_PAY;
  }

  /**
   * Get true if invoice is type "COBRANZA RYA"
   */
  get isDebtRYA(): boolean {
    return (
      this.iMovementType === InvoiceMovementType.CODE_DEBT_RYA &&
      this.sTypeCode === InvoiceType.CODE_ERECIBO
    );
  }

  /**
   * Get true if invoice movement is type COBRANZA
   */
  get isDebt(): boolean {
    return InvoiceModule.isDebt;
  }

  /**
   * Get true if invoice is type BUY|PAY and has been processed
   */
  get isProcessed(): boolean {
    return this.obInvoice.get("is_processed", false);
  }

  /**
   * Get true if invoice is type BUY|PAY and can set to processed
   */
  get isProcessable(): boolean {
    return this.obInvoice.get("is_processable", false);
  }

  /**
   * Get true if company use accounting and invoice can update accounting code
   */
  get canUpdateAccountingCode() {
    return (
      (this.isReceiptType || this.isReceived || this.isSales || this.isDebt) &&
      canModuleAccess("accounting.entries")
    );
  }

  /**
   * Invoices of type e-Recibo can't have empty items
   */
  get canHaveEmptyItems() {
    return (
      !this.signed &&
      !this.isReceiptType &&
      this.iMovementType !== InvoiceMovementType.CODE_RECEIPT_CORRECT
    );
  }

  setPositions(
    arPositions: InvoicePosition[] | InvoicePositionData[],
    force: boolean = false
  ) {
    InvoiceModule.setPositions(arPositions, false);
  }

  getCurrencyFormat(fValue: number, sCode?: string | number) {
    if (!this.currency && !sCode) {
      return fValue;
    }

    if (!sCode && this.currency) {
      sCode = this.currency.code;
    }

    if (isNumber(sCode)) {
      const obCurrency = AppModule.currencies.find({ id: sCode });
      if (obCurrency) {
        sCode = obCurrency.code;
      }
    }

    return sCode && isString(sCode) ? currencyFormat(fValue, sCode) : fValue;
  }

  /**
   * Add an empty item position to invoice
   * @date 29/7/2022 - 06:00:53
   * @author Planeta del Este
   * @returns {void}
   */
  addEmptyItemPosition(): void {
    // Invoices of type e-Recibo only add items by references
    if (!this.canHaveEmptyItems) {
      this.bAddEmpty = false;
    }

    debug("add empty: ", this.bAddEmpty);

    if (this.hasEmptyPosition()) {
      debug("has empty");

      this.bAddEmpty = false;
      return;
    }

    if (!this.bAddEmpty) {
      // First remove all empty positions
      this.bAddEmpty = true;
      this.removeEmptyPositions();
      return;
    }

    if (this.signed) {
      this.bAddEmpty = false;
      return;
    }

    const obPositionData: InvoicePositionData | Record<string, any> = {
      quantity: 1,
      price: 0,
      price_with_discounts: 0,
    };

    if (this.currency) {
      obPositionData.currency_id = this.currency.id;
    }

    const obPosition = new InvoicePosition(obPositionData);
    obPosition.set("idx", uniqueId());

    this.addItemPosition(obPosition, false);
  }

  /**
   * Check for empty position
   *
   * @date 29/7/2022 - 05:59:55
   * @author Planeta del Este
   *
   * @returns {boolean}
   */
  hasEmptyPosition(): boolean {
    if (isNil(this.positions) || isEmpty(this.positions)) {
      return false;
    }

    const arEmptyPositions = filter(this.positions, (obPosition) => {
      const obPositionData: Partial<InvoicePositionData> =
        obPosition instanceof InvoicePosition
          ? obPosition.attributes
          : obPosition;

      return invalidItemPosition(obPositionData);
    });

    return arEmptyPositions.length > 1;
  }

  /**
   * Remove empty positions
   *
   * @date 29/7/2022 - 05:47:58
   * @author Planeta del Este
   * @returns {void}
   */
  removeEmptyPositions(): void {
    this.fnRemoveEmptyPositions();
  }

  _removeEmptyPositions() {
    if (this.signed || this.sTypeCode === InvoiceType.CODE_EREMITO) {
      return;
    }

    const arValidPositions = filter(this.positions, (obPosition) => {
      const obPositionData: Partial<InvoicePositionData> =
        obPosition instanceof InvoicePosition
          ? obPosition.attributes
          : obPosition;

      return !invalidItemPosition(obPositionData);
    });

    // this.obInvoice.set("positions", arValidPositions);
    this.setPositions(arValidPositions);
    debug("remove positions: ", arValidPositions.length);

    if (this.bAddEmpty) {
      debug("add empty from remove: ", this.bAddEmpty);

      this.addEmptyItemPosition();
      this.bAddEmpty = false;
    }
  }

  /**
   * Add a new item position to invoice
   * @param {InvoicePosition} obPosition
   * @param {boolean} validate
   * @return {void}
   */
  addItemPosition(obPosition: InvoicePosition, validate = true): void {
    if (validate && invalidItemPosition(obPosition.attributes)) {
      debug("invalid", obPosition.attributes);

      return;
    }

    const arPositions: InvoicePosition[] = [...this.positions];
    arPositions.unshift(obPosition);
    // this.obInvoice.set("positions", arPositions);
    this.setPositions(arPositions);

    if (!validate && this.bAddEmpty) {
      this.bAddEmpty = false;
      debug("empty added: ", this.bAddEmpty);
    }
  }

  /**
   * Remove item position from invoice at iIndex position
   * @param {number} iIndex
   */
  deleteItemPosition(iIndex: number | number[]) {
    const arIndex = castArray(iIndex);
    const arPositions: InvoicePosition[] = filter(
      this.positions,
      (obPosition, index) => {
        return !arIndex.includes(index);
      }
    );

    // Invoice of type e-Recibo must sync reference with positions
    if (this.isReceiptType) {
      const obInvoiceData = get(this.positions, iIndex) as
        | InvoicePositionData
        | undefined;

      if (obInvoiceData && this.references.length) {
        const arReferences = filter(
          this.references,
          (obReference) => obReference.invoice_ref_id !== obInvoiceData.item_id
        );
        this.obInvoice.set("references", arReferences);
      }
    }

    // this.obInvoice.set("positions", arPositions);
    this.setPositions(arPositions);

    if (isEmpty(arPositions) && this.canHaveEmptyItems) {
      this.addEmptyItemPosition();
    }
  }

  /**
   * If the invoice has an ID, don't do anything. Otherwise, set the positions to an empty array
   *
   * @return {void}
   */
  resetPositions(bForce = false): void {
    if (bForce) {
      // this.obInvoice.set("positions", []);
      this.setPositions([]);
      return;
    }

    if (
      this.obInvoice.id ||
      (this.$route.name && this.$route.name.includes("copy"))
    ) {
      return;
    }

    // this.obInvoice.set("positions", []);
    this.setPositions([]);
  }

  resetReferences(): void {
    if (this.obInvoice.id) {
      return;
    }

    this.obInvoice.set("references", []);
  }

  resetPaymentMethods(): void {
    if (this.obInvoice.id) {
      return;
    }

    this.obInvoice.set("payment_methods", []);
  }

  /**
   * Update invoice position data
   * @param {InvoicePosition} obPosition
   * @param {number} iIndex
   * @returns {void}
   */
  updateItemPosition(obPosition: InvoicePosition, iIndex: number): void {
    if (iIndex === -1) {
      this.addItemPosition(obPosition);
      return;
    }

    const arPositions: InvoicePosition[] = [...this.positions];
    arPositions.splice(iIndex, 1, obPosition);

    // this.obInvoice.set("positions", arPositions);
    this.setPositions(arPositions);
  }

  /**
   * It takes an array of invoice positions and returns an array of tax types with their amounts
   * @param {InvoicePosition[]} arPositions - InvoicePosition[] - an array of positions
   */
  onSetTaxAmountFromPositions(arPositions?: InvoicePosition[]) {
    // e-Remito invoices doesn't use taxes and prices
    if (this.sTypeCode == InvoiceType.CODE_EREMITO) {
      return;
    }

    const arTaxes: TaxTypeDataWithAmount[] = [];

    if (this.signed) {
      const arTaxValueList = this.obInvoice.get("tax_value_list", []);

      if (!arTaxValueList.length && !this.arTaxes.length) {
        return;
      }

      forEach(arTaxValueList, (obTaxItem) => {
        const obTaxType = InvoiceModule.taxTypeCollection.find({
          code: obTaxItem.code,
        });
        if (!obTaxType) {
          return;
        }
        const fPercent = number(obTaxType.percent);
        const obTaxData: TaxTypeDataWithAmount =
          obTaxType.toJSON() as TaxTypeDataWithAmount;
        set(obTaxData, "amount", fPercent ? obTaxItem.tax_amount : 0);
        set(obTaxData, "total", obTaxItem.total_amount);
        arTaxes.push(obTaxData);
      });
    } else {
      if (!arPositions) {
        arPositions = this.positions;
      }

      if (arPositions && arPositions.length) {
        forEach(arPositions, (obPosition) => {
          this.onSetTaxAmount(obPosition, arTaxes);
        });
      }
    }

    debug("Set amount of taxes:", arTaxes.length);
    InvoiceModule.setTaxesAmount(arTaxes);
    applyDiscounts(this.positions, this.discounts, this.priceWithTax);
  }

  /**
   * It calculates the tax amount for a given position
   * @param {InvoicePosition} obPosition - InvoicePosition - the position object
   * @param {Partial<TaxTypeDataWithAmount>[]} arTaxes - Partial<TaxTypeDataWithAmount>[] - an array of tax types with the
   * amount of tax for each type.
   * @param bWithDiscounts
   * @returns the tax amount for the invoice position.
   */
  onSetTaxAmount(
    obPosition: InvoicePosition,
    arTaxes: Partial<TaxTypeDataWithAmount>[],
    bWithDiscounts = true
  ) {
    if (!obPosition || !obPosition.tax_type_id) {
      return;
    }

    if (!(obPosition instanceof InvoicePosition)) {
      obPosition = new InvoicePosition(obPosition);
    }

    const obTaxType = InvoiceModule.taxTypeCollection.find({
      id: obPosition.tax_type_id,
    });

    if (!obTaxType) {
      return;
    }

    const iIdx = findIndex(arTaxes, { id: obTaxType.id });
    let obTaxData: Record<string, any> | undefined = find(arTaxes, {
      id: obTaxType.id,
    });

    if (!obTaxData) {
      obTaxData = obTaxType.toJSON() as TaxTypeDataWithAmount;
      set(obTaxData, "amount", 0);
      set(obTaxData, "total", 0);
    }

    const sPriceField =
      this.discounts.length && bWithDiscounts
        ? "price_with_discounts"
        : "price_without_tax";
    const fPositionPrice = obPosition.get(sPriceField); // || obPosition.price;
    const fPrice = math.multiply(fPositionPrice as number, obPosition.quantity);
    const fTotal = math.add(fPrice, obTaxData.total);
    let fTaxPrice = percent(fPrice, obTaxType.percent); // (fPrice * obTaxType.percent) / 100;
    fTaxPrice = math.add(fTaxPrice, obTaxData.amount);

    set(obTaxData, "amount", round(fTaxPrice, 3));
    set(obTaxData, "total", round(fTotal, 3));

    if (iIdx === -1) {
      arTaxes.push(obTaxData);
    }
  }

  onSetPriceWithTax() {
    const obCompanyMovements = this.companyMovementTypeConfig.filter(
      (obItem: CompanyMovementType) => {
        return (
          obItem.movement_type_config.invoice_movement_type_id ===
          this.obInvoice.invoice_movement_type_id
        );
      }
    );

    if (obCompanyMovements.length) {
      let obMovement: CompanyMovementType | undefined | null = null;
      if (obCompanyMovements.length === 1) {
        obMovement = obCompanyMovements.first() as CompanyMovementType;
      } else {
        obMovement = obCompanyMovements.find(
          (obItem: CompanyMovementType) =>
            obItem.movement_type_config.is_cash === this.obInvoice.is_cash
        ) as CompanyMovementType;
      }

      if (obMovement) {
        this.obInvoice.set("price_with_tax", obMovement.price_with_tax);
      }
    }
  }
}
